import { FC, useMemo } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { lightColors, StyledButton } from '@tryhackme/thm-ui-components';
import { ThemeProvider, useTheme } from 'styled-components';
import { checkIfShouldShow, checkMatch } from 'src/features/announcements/helpers';
import { useGetUserQuery } from 'src/common/slices';
import { useAnnouncement } from 'src/features/announcements/hooks/use-announcement';
import { AnnouncementName } from 'src/common/enums/announcements';
import { useAnnouncementCta } from 'src/features/announcements/hooks/use-announcement-cta';
import { Countdown } from 'src/features/announcements/components/countdown';
import { useCampaign } from '../use-campaign';
import { StyledBar, StyledCountdown } from './fireship-bar.styles';
interface Props {
  overrideRules?: boolean;
  isCongratulationModal?: boolean;
}
const pathsToExclude = ['/onboarding/*', '/pricing', '/welcome', '/payment/pending', '/feedback/subscription', '/room/*', '/echo', '/path/outline/*', '/recapme/*', '/login/*', '/certification/*'];
const endTime = new Date('2025-04-04T17:00:00.000+01:00');
export const FireshipCampaignBar: FC<Props> = ({
  overrideRules,
  isCongratulationModal
}) => {
  const theme = useTheme();
  const campaignData = useCampaign();
  const {
    announcement
  } = useAnnouncement(AnnouncementName.FIRESHIP_2025);
  const [ctaText, urlToNavigate] = useAnnouncementCta();
  const {
    pathname
  } = useLocation();
  const {
    data: userDataResponse
  } = useGetUserQuery();
  const shouldShow = useMemo(() => {
    const isPremium = userDataResponse?.data?.user?.isPremium;
    const isOnManageSub = checkMatch(['/manage-account/subscription'], pathname).doesMatch;
    const showBasedOnSubStatus = isPremium ? !isOnManageSub : true;
    return announcement && checkIfShouldShow(pathsToExclude, [], pathname) && showBasedOnSubStatus;
  }, [pathname, userDataResponse, announcement]);
  const url = userDataResponse?.data ? urlToNavigate : '/signup?source=fireship';
  return (overrideRules || shouldShow) && campaignData ? <ThemeProvider theme={{
    ...theme,
    colors: lightColors
  }}>
      <StyledBar isCongratulationModal={isCongratulationModal}>
        <p>
          Fireship Viewers Exclusive <span>20% off</span> annual subscriptions!
        </p>
        <StyledButton as={Link} to={url} variant="solid" color="primary" size="small">
          {ctaText}
        </StyledButton>
        {announcement && <StyledCountdown>
            <Countdown dark startTime={announcement.startTime} endTime={endTime.getTime()} />
          </StyledCountdown>}
      </StyledBar>
    </ThemeProvider> : null;
};